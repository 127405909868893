<template>
  <div>
    <div class="container-fluid">
      <div class="payment-status">
        <div class="payment-status__content">
          <img
            src="@/assets/img/clipboard-x.svg"
            class="payment-status__img"
          />
          <h6 class="payment-status__heading">Booking failed!</h6>
          <p class="payment-status__message">
            Failed to complete your booking. Please check your payment details or try with another payment options.
          </p>
          <div class="payment-status__btns-wrap">
            <button type="submit" class="btn btn-block">View booking</button>
            <router-link
              :to="{ name: 'book_now' }"
              type="submit"
              class="btn btn-block btn-secondary"
            >
              make another booking
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
